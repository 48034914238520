<template>
  <aside class="as" :class="{ shrink: !isSideBarExpanded, active: !isSideBarActive}" v-if="!isHiddenHeadAndSideBar">
    <mq-layout :mq="['lg', 'xlg', 'xl']" class="as__logo__container">
      <router-link :to="{ name: 'home' }" class="as__logo">
        <Logo/>
      </router-link>
      <a href="#" @click.prevent="changeSideBarState" class="as__toggle">
        <SideBarIco/>
      </a>
    </mq-layout>
    <mq-layout :mq="['sm', 'xsm', 'md']" class="as__logo__container">
      <a href="#" class="as__toggle">
        <SideBarIco/>
      </a>
    </mq-layout>
    <!--    <perfect-scrollbar tag="nav" class="as__items" :options="psOptions">-->
    <nav class="as__items">
      <div class="as__item">
        <SidebarMenu/>
      </div>
      <div class="as__item as__item__bottom">
        <ul class="as__menu">
          <li>
            <router-link :to="{ name: 'cabinet' }" class="as__menu__link" :class="{ active: isSubRouterLinkActive('cabinet')}">
              <i class="as__menu__link__icon">
                <CabinetIco/>
              </i>
              <span class="as__menu__link__title">Кабинет</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'faq' }" class="as__menu__link" :class="{ active: isSubRouterLinkActive('faq') || isSubRouterLinkActive('support') || isSubRouterLinkActive('question')}">
              <i class="as__menu__link__icon">
                <QuestionIco/>
              </i>
              <span class="as__menu__link__title">FAQ (Вопрос/ответ)</span>
            </router-link>
          </li>
          <!--          <li>-->
          <!--            <router-link :to="{ name: 'support' }" class="as__menu__link" :class="{ active: isSubRouterLinkActive('support')}">-->
          <!--              <i class="as__menu__link__icon">-->
          <!--                <HelpIco/>-->
          <!--              </i>-->
          <!--              <span class="as__menu__link__title">Служба поддержки</span>-->
          <!--            </router-link>-->
          <!--          </li>-->
        </ul>
      </div>
    </nav>
    <!--    </perfect-scrollbar>-->
  </aside>
</template>

<script>
import SidebarMenu from '@/components/layouts/SidebarMenu'
import Logo from '@/components/svg/logo_text'
import QuestionIco from '@/components/svg/question'
import SideBarIco from '@/components/svg/sidebar'
import CabinetIco from '@/components/svg/cabinet'

export default {
  name: 'SideBar',
  components: {
    SidebarMenu,
    CabinetIco,
    Logo,
    SideBarIco,
    QuestionIco
  },
  async beforeCreate () {
    await this.$store.dispatch('expiredTasks', this)
    await this.$store.dispatch('unprocessesAppeals', this)
    await this.$store.dispatch('unprocessedLeads', this)
  },
  data () {
    return {
      psOptions: {
        suppressScrollX: true,
        wheelPropagation: false,
        minScrollbarLength: 0
      }
    }
  },
  mounted () {
    document.querySelectorAll('.as__menu li').forEach(element => {
      element.addEventListener('mouseover', function () {
        let submenu = element.querySelector('.as__submenu')
        if (submenu) {
          submenu.style.top = element.offsetTop + 'px'
        }
      })
    })
  },
  computed: {
    isHiddenHeadAndSideBar () {
      return !this.$store.state.authToken || this.$store.state.isHiddenHeadAndSideBar
    },
    isSideBarActive () {
      return this.$store.state.sideBarState === 'expanded'
    },
    isSideBarExpanded () {
      if (window.innerWidth <= 768) {
        return false
      } else {
        return this.$store.state.sideBarState === 'expanded'
      }
    },
    currentRoute () {
      return this.$route
    }
  },
  watch: {
    '$route' () {
      if (window.innerWidth <= 768) {
        this.$store.dispatch({ type: 'setSideBarState', value: 'nonexpanded' })
        document.querySelectorAll('.as__submenu').forEach(submenu => {
          submenu.querySelector('a').blur()
        })
      }
    }
  },
  methods: {
    mobileHandle (route) {
      if (window.innerWidth > 768) {
        // console.log(1)
        this.$router.push(route)
      }
    },
    changeSideBarState () {
      let value = this.isSideBarExpanded ? 'nonexpanded' : 'expanded'
      this.$store.dispatch({ type: 'setSideBarState', value: value })
    },
    isSubRouterLinkActive (routerName) {
      if (typeof routerName === 'string') {
        return routerName === this.currentRoute.name || routerName === this.currentRoute.meta.parent
      } else {
        return routerName.filter(item => item === this.currentRoute.name || item === this.currentRoute.meta.parent).length !== 0
      }
    }
  }
}
</script>

<style lang="stylus">
.as
  fixed left top
  height 100vh
  background $dark
  text-align center
  transition all 0.3s
  z-index 99
  width 280px
  display flex
  flex-direction column

  &.shrink
    width 100px

    +below(768px)
      left 0

      &.active
        left -100px

  &__logo
    display flex
    align-items center
    justify-content center
    flex 1

    svg

      #logo__text
        display none

    ^[0].shrink &
      display none

    &__container
      display flex
      width 100%
      background $white
      border-right 1px solid $pinky

  &__toggle
    display flex
    flex-shrink 0
    align-items center
    justify-content center
    padding 27px 30px

    ^[0].shrink &
      width 100%

    +below(540px)
      height 60px

  &__items
    overflow-y auto
    overflow-x hidden
    -ms-overflow-style none
    scrollbar-width 0

  &__item
    display flex
    align-items flex-start
    justify-content flex-start
    padding 20px 15px
    flex-shrink 0

    ^[0].shrink &
      padding 10px 5px

    &__bottom
      background rgba($white, 0.1)
      padding 15px

    &s
      display flex
      flex-direction column
      justify-content space-between
      padding-bottom 10px
      height calc(100vh - 80px)
      flex-shrink 0

      ^[0].shrink &
        padding 0

      +below(540px)
        height calc(100vh - 120px)

  &__submenu
    display flex
    flex-direction column
    padding 0 0 0 60px

    ^[0].shrink &
      min-width 200px
      display none
      absolute right 5px top
      background: rgba($c4E555A, 0.9);
      box-shadow: 0 20px 30px rgba($c4E555A, 0.17);
      border-radius: 0 3px 3px 0;
      padding: 0;
      z-index 99
      transform: translate(calc(100% + 5px));

      li:not(:last-child)
        border-bottom 1px solid $pinky

    &__link
      font-size: 15px;
      line-height: 18px;
      color: $pinky;
      position relative
      display flex
      align-items center
      width 100%
      padding 10px 0

      ^[0].shrink &
        padding 22px 16px

      &.active
        color $orange

      &:before
        content ""
        width 5px
        height 5px
        border-radius 100%
        background $white
        display flex
        absolute left -15px

        ^[0].shrink &
          display none

  &__menu
    display flex
    flex-direction column
    padding 0
    width 100%
    z-index 100

    ^[0].shrink & li:hover .as__submenu
      display flex

    ^[0].shrink.active & li .as__submenu
      +below(768px)
        display none

    li
      display flex
      flex-direction column
      align-items flex-start
      justify-content flex-start

    &__link
      display flex
      align-items center
      justify-content flex-start
      padding 20px 15px
      width 100%
      font-size: 17px
      line-height: 21px
      color: $gray
      border-radius: 10px;

      &.show_in_mobile
        display none

      ^[0].shrink &
        flex-direction column
        align-items center
        justify-content center
        font-size: 11px;
        line-height: 13px;
        color: $gray;
        padding 10px 0

      &:hover
        color $orange

        svg path[fill]
          fill $orange

        svg path
          stroke $orange

        svg polygon
          stroke $orange

        svg line
          stroke $orange

      &.active
        background rgba($white, 0.1)

      &.active &__icon svg path[fill]
        fill $orange

      &.active &__icon svg path
        stroke $orange

      &.active &__icon svg polygon
        stroke $orange

      &.active &__icon svg line
        stroke $orange

      &__icon
        width 24px
        height 24px
        display flex
        align-items center
        flex-shrink 0

      &__title
        padding-left 20px
        text-align left

        ^[0].shrink &
          padding-left 0
          padding-top 10px
          text-align center

      &__badge
        display flex
        align-items center
        justify-content center
        margin-left auto
        font-size: 15px;
        line-height: 18px;
        color: $white;
        width 30px
        height 30px
        background $red
        border-radius 100%

        ^[0].shrink &
          position: absolute;
          right: 0;
          top: 0;

        &--orange
          background $orange
</style>
