<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9872 6.5C15.8096 8.97828 13.9936 11 12 11C10.0064 11 8.18722 8.97875 8.01278 6.5C7.83154 3.92188 9.59861 2 12 2C14.4014 2 16.1685 3.96875 15.9872 6.5Z" stroke="#E8E8F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M11.9998 14C7.9218 14 3.7829 16.4 3.01699 20.93C2.92465 21.476 3.21432 22 3.75008 22H20.2494C20.7857 22 21.0754 21.476 20.983 20.93C20.2166 16.4 16.0777 14 11.9998 14Z" stroke="#E8E8F6" stroke-width="1.5" stroke-miterlimit="10"></path>
  </svg>
</template>

<script>
export default {
  name: 'CabinetIco'
}
</script>
